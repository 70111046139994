import React, { Fragment } from "react";
import styles from "../../styles/pages/myaccount.module.scss";
import MUITextField from "../common/ui-components/input/MUITextField";
import { useRouter } from "next/router";
import { VerifyEmailOTP, SendEmailOTP } from "../../api/myaccount";
import { getApplication, getNextPath, getUrlParams } from "../../utils/index";
import Cookies from "js-cookie";
import { setCustomerCookie } from "../../api/cookies";
import {
  pushToSegmentIdentify,
  resetUserSegment,
} from "../../utils/tracking/index";
import { loginSuccess } from "../../tracking/segment/loginPage";
import store from "../../redux";
import { downloadCertificatePopup } from "../../redux/actions/orderActions";

const EmailVerification = (props: any) => {
  const [email, setEmail] = React.useState<string>(props.email);
  const router = useRouter();
  const [timer, setTimer] = React.useState<any>(null);
  const [resenttimer] = React.useState<any>(null);
  const [count, setCount] = React.useState<number>(0);
  const [emailOtp, setEmailOtp] = React.useState<string>("");
  const [emailOtpError, setEmailOtpError] = React.useState<string>("");

  const sendEmailOTP = () => {
    if (email || props.email) {
      SendEmailOTP({
        application: getApplication(),
        data: {
          type: "customer_merging",
          email: email || props.email,
          template: "email-otp-v1",
          first_name: props.first_name ? props.first_name : ' ',
          last_name: props.last_name ? props.last_name : ' ',
        },
      }).then(() => {
        setCountdownTimer(30);
      });
    }
  };

  const verifyEmailOTP = () => {
    if (emailOtp) {
      VerifyEmailOTP({
        application: getApplication(),
        data: {
          type: "customer_merging",
          email: email,
          template: "email-otp-v1",
          otp: emailOtp,
          first_name: props.first_name ? props.first_name: ' ',
          last_name: props.last_name ? props.last_name : ' ',
          receive_whatsapp_updates: true,
          customer_token: props.customer_token,
          cart_id: props.cart?.token_id,
        },
      })
        .then((res: any) => {
          if (res.data?.status) {
            if ("is_authenticate" in res.data) {
              Cookies.set("is_authenticate", true);
              setCustomerCookie(res?.data);
              props.updateCustomerDetails({
                customer_token: res?.data?.token,
                ...res.data,
              });
              resetUserSegment(res?.data);
              pushToSegmentIdentify(res?.data);
              loginSuccess({ method: "phone login", phone: props.phone });
            }
            if (router.query.next) {
              let path = getUrlParams(getNextPath(router.asPath),router);
              if(router.query.download_certificate){
                store.dispatch(downloadCertificatePopup({ download_certificate_popup: true, download_osid: router.query.osid }))
              }
              window.location.href = window.location.origin + path;
            } else if (router.query.kycstatus) {
              if(router.query.kycstatus==="true")
              {
                router.push('/myaccount/#plans')
              }
              else if(router.query.kycstatus==="false")
              {
                router.push('/myaccount/#profile')
              }
              else
              {
                router.push(`/`);
              }
            } else {
              router.push("/");
            }
          } else {
            setEmailOtpError("Invalid OTP");
          }
        })
        .catch((err: any) => {
          console.log("error =>", err);
        });
    }
  };

  const setCountdownTimer = (countFrom) => {
    let counter = countFrom;
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
    if (resenttimer) {
      clearTimeout(resenttimer);
    }
    setCount(counter);

    let countDownTimer = setInterval(function () {
      counter = counter - 1;
      setCount(counter);
      if (!(counter > 0)) {
        clearInterval(countDownTimer);
        setTimer(null);
      }
    }, 1000);
    setTimer(countDownTimer);
  };

  React.useEffect(() => {
    if(props.email){
      setEmail(props.email)
      sendEmailOTP()
      setCountdownTimer(30);
    }
  }, [props.email]);

  let ctaEmailText: any,
    ctaEmailClick: any = null;

  if (count != 0) {
    ctaEmailText = `OTP Sent (${count})`;
  } else {
    ctaEmailText = "Resend OTP";
    ctaEmailClick = sendEmailOTP;
  }

  return (
    <Fragment>
      <div className={styles.input_parent_phone}>
        <div className={styles.top_heading}>
          <MUITextField
            className={styles.password}
            isRequired={true}
            type="text"
            isForm={true}
            label="Email"
            value={email}
            onChange={() => {}}
            propsError={""}
            maxLength={50}
            helperText={"OTP is sent to your email"}
            ctaText={ctaEmailText}
            ctaClick={ctaEmailClick}
            isDisabled={true}
          />
        </div>
        <div className={styles.top_heading}>
          <MUITextField
            className={styles.password}
            isRequired={true}
            type="text"
            isForm={true}
            label="Enter OTP"
            value={emailOtp !==""?emailOtp.replace(/[^0-9]/gi, ""):""}
            onChange={(e) => {
              if (e.target.value.length < 5) {
                setEmailOtp(e.target.value);
              }
            }}
            propsError={emailOtpError}
            maxLength={50}
            onKeyPress={(e) => {
              e.key === "Enter" && verifyEmailOTP();
            }}
          />
        </div>
        <div className={styles.top_heading}>
          <div
            className={`${styles.button} ${
              emailOtp.length == 4 ? "" : `${styles.disabled}`
            }`}
          >
            <a
              onClick={() => {
                if (emailOtp) {
                  verifyEmailOTP();
                }
              }}
            >
              Verify OTP
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailVerification;
