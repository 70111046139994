import { PushToTracker  } from "../../utils/tracking";

// data contains - { phone_number }
export const otpRequestSuccess = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'OTP Requested', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { method }
export const loginSuccess = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Login Success', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { active_sr_ids }
export const raiseSrPageViewed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Raise SR Page Viewed', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const notFindMyPlanClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":"Don't Find My Plan Clicked", "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const logOutClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":"Logout Successful", "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}
