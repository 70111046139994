import React, { Fragment, useState } from "react";
import styles from "../../styles/pages/myaccount.module.scss";
import MUITextField from "../common/ui-components/input/MUITextField";
import { useRouter } from "next/router";
import { SendOTP, VerifyOTP } from "../../api/myaccount";
import Cookies from "js-cookie";
import { setCustomerCookie } from "../../api/cookies";
import {
  getPageURL,
  pushToSegmentIdentify,
  resetUserSegment,
} from "../../utils/tracking/index";
import { loginSuccess } from "../../tracking/segment/loginPage";
import { resendOtpClicked } from "../../tracking/segment/checkoutPage";
import { downloadCertificatePopup } from "../../redux/actions/orderActions";
import store from "../../redux";
import { getUrlParams, getValidPath } from "../../utils";
import moment from 'moment'
import { secondsToHHMM } from "../../utils";

const PhoneStep = (props: any) => {
  const [otp, setOtp] = React.useState<string>("");
  const [otpError, setOtpError] = React.useState<string>("");
  const router = useRouter();
  const [resenttimer] = React.useState<any>(null);
  const [count, setCount] = React.useState<number>(0);
  const [timer, setTimer] = React.useState<any>(null);
  const [blockTime, setBlockTime] = React.useState<any>(''); 
  const [intervalId,setIntervalId] = useState<any>(null);
  const sendOtpClick = () => {
    SendOTP({
      application: "website",
      data: {
        phone: props.phone,
        type: "customer-login",
        template: "otp_customer_auth",
      },
    }).then(() => {
      resendOtpClicked({ phone: props.phone, ...getPageURL() });
      setCountdownTimer(30);
    });
  };

  const setCountdownTimer = (countFrom) => {
    let counter = countFrom;
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
    if (resenttimer) {
      clearTimeout(resenttimer);
    }
    setCount(counter);

    let countDownTimer = setInterval(function () {
      counter = counter - 1;
      setCount(counter);
      if (!(counter > 0)) {
        clearInterval(countDownTimer);
        setTimer(null);
      }
    }, 1000);
    setTimer(countDownTimer);
  };

  React.useEffect(() => {
    setCountdownTimer(30);
  }, []);

  const verifyOtp = () => {
    if (!otp) {
      setOtpError("Required Field");
    } else if (otp.length != 4) {
      setOtpError("Invalid Otp");
    } else if (otp.length == 4) {
      VerifyOTP({
        application: "website",
        data: {
          otp: otp,
          phone: props.phone,
          type: "customer-login",
          cart_id: props.cart?.token_id,
        },
      }).then((res: any) => {
        if (res.response.status == 200 && !res?.data.otp) {
          props.updateCustomerDetails({
            customer_token: res?.data?.token,
            ...res.data
          });
          if (
            res?.data?.customer_conflict && res?.data?.email_activation_pending
          ) {
            props.progressStep(3);
          } else if (res?.data?.customer_conflict) {
            props.progressStep(4);
          } else if (res?.data?.email_activation_pending) {
            props.progressStep(3);
            if("is_authenticate" in res.data){
              Cookies.set("is_authenticate", true);
              setCustomerCookie(res?.data);
              resetUserSegment(res?.data);
              pushToSegmentIdentify(res?.data);
              loginSuccess({ method: "phone login", phone: props.phone });
            }
          } else if ("is_authenticate" in res.data) {
            Cookies.set("is_authenticate", true);
            setCustomerCookie(res?.data);
            resetUserSegment(res?.data);
            pushToSegmentIdentify(res?.data);
            loginSuccess({ method: "phone login", phone: props.phone });
            if (router.query.next) {
              let path:any = router.query.next;
              router.push(getUrlParams(getValidPath(path),router))
              if(router.query.download_certificate){
                store.dispatch(downloadCertificatePopup({ download_certificate_popup: true, download_osid: router.query.osid }))
              }
            }
            else if (router.query.kycstatus) {
              if(router.query.kycstatus==="true")
              {
                router.push('/account/plans')
              }
              else if(router.query.kycstatus==="false")
              {
                router.push('/account/profile')
              }
              else
              {
                router.push(`/`);
              }
              props.progressStep(1);
            } else {
              router.push(`/`);
            }
          }
        }  else {
          const attempts = res.data.remaining_attempts
          setOtpError(`Incorrect OTP ${attempts} ${attempts === 1 ? 'attempt' : 'attempts'} left.`);          
        }
      }).catch(e => {
        if (!(e instanceof Response)){
          return
        }
        e.json().then((res:any) => {
          if(res.login_attempt_info){
            let blockTime = moment().add(res.remaining_seconds, 'seconds');
            let diff = blockTime.diff(moment(),'seconds')
            setBlockTime(blockTime)
            setOtpError(`Your account has been blocked for next ${secondsToHHMM(diff)} due to multiple incorrect OTP attempts.`);
            clearInterval(intervalId)
            let blockTimerInterval = setInterval(function () {
              diff = blockTime.diff(moment(),'seconds')
              setOtpError(`Your account has been blocked for next ${secondsToHHMM(diff)} due to multiple incorrect OTP attempts.`);
              if(diff <= 0){
                clearInterval(blockTimerInterval)
                setBlockTime(null)
                setOtpError('');
              }
            }, 1000);
            setIntervalId(blockTimerInterval)
          }
        })
      })
    }
  };

  return (
    <Fragment>
      <div className={styles.input_parent_phone}>
        <div className={styles.top_heading}>
          <MUITextField
            className={styles.password}
            isRequired={true}
            type="text"
            label="Mobile Number"
            value={props.phone}
            onChange={() => {
              props.progressStep(1);
            }}
            isDisabled={props.customer.phone_prefilled}
          />
        </div>

        <div className={styles.top_heading}>
          <MUITextField
            id="otp"
            className={styles.password}
            isAutoFocus={true}
            isRequired={true}
            type="tel"
            isForm={true}
            label="OTP"
            propsError={otpError}
            value={otp !== "" ? otp.replace(/[^0-9]/gi, "") : ""}
            onChange={(e: { target: { name: string; value: string } }) => {
              setOtpError("");
              setOtp(e.target.value);
            }}
            maxLength={4}
            onKeyPress={(e) => {
              e.key === "Enter" && verifyOtp();
            }}
            isDisabled={blockTime > moment()}
            autoComplete="off"
          />
        </div>

        <div className={styles.password_div}>
          { blockTime && blockTime > moment() || count == 0 ? (
            <div className ={`${blockTime && blockTime > moment() && styles.disabled}`}> 
                <a
                  className={styles.forgot_passsword}
                  onClick={() => {
                    (blockTime && blockTime > moment() || !blockTime) && sendOtpClick();
                  }}
                >
                  Resend OTP
                </a>
              </div>
            ) : (
              <div
                className={styles.forgot_passsword}
                style={{ cursor: "default" }}
              >
                <span>OTP Sent ({count})</span>
              </div>
          )}
        </div>
        <div className={styles.top_heading}>
          <label className={styles.tnc} htmlFor="tnc-3">
            By proceeding, you agree to the{" "}
            <a
              href="/terms-of-service/website"
              target="blank"
              style={{ cursor: "pointer" }}
            >
              Terms of Service
            </a>
          </label>
          {
            <div
              className={`${styles.button} ${
                !(props.phone && otp.length == 4) || (blockTime && blockTime > moment()) ? `${styles.disabled}` : "" 
              }`}
            >
              <a
                onClick={(e) => {
                 (blockTime && blockTime > moment() || !blockTime) && verifyOtp();
                }}
              >
                Sign In
              </a>
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default PhoneStep;
