import React, { Fragment } from "react";
import styles from "../../styles/pages/myaccount.module.scss";
import MUITextField from "../common/ui-components/input/MUITextField";
import { useRouter } from "next/router";
import Image from "../common/Image";
import Button from "../common/ui-components/input/Button";
import Checkbox from "../common/ui-components/input/Checkbox";
import { SendEmailVerification, PartialUpdate } from "../../api/myaccount";
import Cookies from "js-cookie";
import { setCustomerCookie } from "../../api/cookies";
import {
  pushToSegmentIdentify,
  resetUserSegment,
} from "../../utils/tracking/index";
import { regExpEmail } from "../../constants";
import store from "../../redux";
import { downloadCertificatePopup } from "../../redux/actions/orderActions";
import { getUrlParams, getValidPath, isPromise } from "../../utils";

const CustomerDetails = (props: any) => {
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const router = useRouter();
  const [errorName, setErrorName] = React.useState<string>("");
  const [errorLastName, setErrorLastName] = React.useState<string>("");
  const [errorEmail, setErrorEmail] = React.useState<string>("");
  const [timer, setTimer] = React.useState<any>(null);
  const [resenttimer] = React.useState<any>(null);
  const [count, setCount] = React.useState<number>(0);
  const [has_whatsapp_opt_in, setWhatsapp] = React.useState<boolean>(true);
  const [, SetResendLink] = React.useState<boolean>(false);
  const [referral, setReferral] = React.useState<string>("");

  const setCountdownTimer = (countFrom) => {
    let counter = countFrom;
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
    if (resenttimer) {
      clearTimeout(resenttimer);
    }
    setCount(counter);

    let countDownTimer = setInterval(function () {
      counter = counter - 1;
      setCount(counter);
      if (!(counter > 0)) {
        clearInterval(countDownTimer);
        setTimer(null);
      }
    }, 1000);
    setTimer(countDownTimer);
  };

  React.useEffect(() => {
    setFirstName(props.first_name)
    setLastName(props.last_name)
    setEmail(props.email||props.display_email)
    if(props.email && props.email_activation_pending){
      SendEmailVerification({email: props.email, id:props.customer_token, customer_token:props.customer.customer_token})
      setCountdownTimer(30);
    }
  }, [props.first_name]);

  const ResendActivationLink = () => {
    SendEmailVerification({ email: email, id:props.customer_token, customer_token:props.customer.customer_token });
    SetResendLink(true);
  };

  const confirmDetailsHandle = () => {
    if (!email) {
      setErrorEmail("Required Field");
    } else if (!regExpEmail.test(email)) {
      setErrorEmail("Enter Valid Email");
    } else if (regExpEmail.test(email)) {
      // need to use update cart api
      PartialUpdate(props.customer_token, {
        id: props.customer_token,
        first_name: firstName,
        last_name: lastName,
        email: email,
        receive_whatsapp_updates:has_whatsapp_opt_in
      })
        .then((res: any) => {
          props.setLeavePage(true);
          props.updateCustomerDetails({
            customer_token: res?.data?.token,
            ...res.data,
            email: res.data.email? res.data.email : res.data.display_email
          });
          Cookies.set("is_authenticate", true);
          setCustomerCookie(res?.data);
          resetUserSegment(res?.data);
          pushToSegmentIdentify(res?.data);
          if (router.query.next) {
            let path:any = router.query.next;
            router.push(getUrlParams(getValidPath(path),router))
            if(router.query.download_certificate){
              store.dispatch(downloadCertificatePopup({ download_certificate_popup: true, download_osid: router.query.osid }))
            }
          }
          else if (router.query.kycstatus) {
            if(router.query.kycstatus==="true")
            {
              router.push('/account/plans')
            }
            else if(router.query.kycstatus==="false")
            {
              router.push('/account/profile')
            }
            else
            {
              router.push(`/`);
            }
          } else {
            router.push(`/`);
          }
        })
        .catch((err: any) => {
          if(!isPromise(err)) {
            throw new Error("error is not a promise", err)
          }
          err.then((err: any) => {
            if ("email" in err) {
              props.updateCustomerDetails({
                customer_conflict: true,
                first_name: firstName,
                last_name: lastName,
                email: email,
              });
            }
            props.progressStep(4);
          });
        });
    }
  };

  let ctaText: any,
    ctaClick: any = null;

  if (!props.email_activation_pending && !errorEmail) {
    {
      if (count!=0) {
        ctaText = `Link Sent (${count})`;
      } else {
        ctaText = "Resend Link";
        ctaClick = ResendActivationLink;
      }
    }
  }
  return (
    <Fragment>
      <div className={styles.input_parent_phone_details}>
        <MUITextField
          className={styles.password}
          isRequired={true}
          type="text"
          isForm={true}
          label="First Name"
          value={firstName}
          onChange={(e: { target: { name: string; value: string } }) => {
            setErrorName("");
            const re = /^[A-Za-z ]+$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              setFirstName(e.target.value);
            } else {
              setErrorName("Enter Only Alphabet");
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length === 0) {
              setErrorName("Required Field");
            }
          }}
          propsError={errorName}
          maxLength={50}
        />

        <MUITextField
          className={styles.password}
          isRequired={true}
          type="text"
          isForm={true}
          label="Last Name"
          value={lastName}
          onChange={(e: { target: { name: string; value: string } }) => {
            setErrorLastName("");
            const re = /^[A-Za-z ]+$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              setLastName(e.target.value);
            } else {
              setErrorLastName("Enter Only Alphabet");
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length === 0) {
              setErrorLastName("Required Field");
            }
          }}
          propsError={errorLastName}
          maxLength={50}
        />

        <MUITextField
          isDisabled={props.email || props.display_email ? true :false}
          className={styles.password}
          isRequired={true}
          type="email"
          isForm={true}
          label="Email"
          value={email}
          onChange={(e) => {
            setErrorEmail("");
            setEmail(e.target.value);
          }}
          onBlur={(e) => {
            if (e.target.value.length == 0) {
              setErrorEmail("Required Field");
            } else if (!regExpEmail.test(e.target.value)) {
              setErrorEmail("Enter Valid Email");
            }
          }}
          andormentText={
            props.email_activation_pending && props.email
              ? "Activation Pending"
              : ""
          }
          propsError={errorEmail}
          maxLength={50}
          helperText={
            props.email_activation_pending && props.email
              ? "Activation link will be sent to your email id"
              : ""
          }
          ctaText={ctaText}
          ctaClick={ctaClick}
        />

        <MUITextField
          className={styles.password}
          type="text"
          isForm={true}
          label="Referral Code(Optional)"
          onChange={(e: { target: { name: string; value: string } }) =>
            setReferral(e.target.value)
          }
          isDisabled={props.exists ? true : false}
          value={referral}
        />

        <div className={`${styles.whatsapp_opt_in_label} ${styles.whatsapp}`}>
          <Checkbox
            id="tnc-3"
            checked={has_whatsapp_opt_in}
            onChange={() => {
              setWhatsapp(!has_whatsapp_opt_in);
            }}
          />
          <label htmlFor="tnc-3" className={styles.whatsapp_opt_in_label}>
            <span style={{ whiteSpace: "nowrap" }}>
              I agree to receive updates on{" "}
            </span>
            <span
              style={{
                display: "flex",
                flexWrap: "nowrap",
                marginLeft: "3px",
              }}
            >
              <Image
                src={"/static/images/social/wa.svg"}
                alt="Whatsapp"
                unoptimized="true"
                width="20"
                height="20"
              />
            </span>
            <span style={{ marginLeft: "3px" }}>WhatsApp</span>
          </label>
        </div>
        <div className={styles.button_actions_sticky} data-varient="sticky">
          <Button
            disabled={!firstName || !lastName || !email}
            className={styles.cart_btn}
            text={"Confirm Details"}
            variant="primary"
            onClick={() => {
              confirmDetailsHandle();
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerDetails;
