import * as orderActionTypes from '../actionTypes/orderActionTypes';

export const setOrderData = (data:any) => {
  return dispatch => {
    dispatch({
      type: orderActionTypes.SET_ORDER_DATA,
      data,
    })
  }
}

export const clearOrderData = () => {
  return dispatch => {
    dispatch({
      type: orderActionTypes.CLEAR_ORDER_DATA
    })
  }
}

export const downloadCertificatePopup = (data:any) => {
  return dispatch => {
    dispatch({
      type: orderActionTypes.DOWNLOAD_CERTIFICATE_POPUP,
      data,
    })
  }
}

export const setOrderStep = (data:any) => {
  return dispatch => {
    dispatch({
      type: orderActionTypes.SET_STEP,
      data,
    })
  }
}

export const setIsVerified = (data:any) => {
  return dispatch => {
    dispatch({
      type: orderActionTypes.SET_IS_VERIFIED,
      data,
    })
  }
}

export const setOrderPhone = (data:any) => {
  return dispatch => {
    dispatch({
      type: orderActionTypes.SET_ORDER_PHONE,
      data,
    })
  }
}
