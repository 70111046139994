import { InputAdornment, TextField } from "@mui/material";
import React, { Fragment } from "react";
import PasswordRules from '../../PasswordRules';
import styles from "../../../../styles/modules/ui-components/ui-textfield.module.scss";
import { validateInput } from "../../../../utils";

interface MUITextFieldProps {
  label: string;
  id?: string;
  type?: string | "text";
  onChange: any;
  onKeyDown?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onBlur?: (e: { target: { name: string; value: string } }) => void;
  hasError?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  regExp?: any;
  inputAttr?: object;
  error?: string;
  noLabel?: boolean | false;
  customValidation?: (e) => { isValid: boolean; msg?: string };
  value: string;
  className?: string;
  propsError?: string;
  isForm?: boolean;
  onFocus?: (e: any) => void;
  readOnly?: boolean;
  noLabe?: boolean;
  name?: string;
  onKeyPress?: (e: any) => void;
  isAutoFocus?: boolean | false;
  isOtp?: boolean | false;
  showPasswordRules?: boolean | false;
  andormentText?: string;
  maxLength?: number;
  helperText?: string
  ctaText?: string;
  ctaClick?: any;
  showEye?: boolean;
  autoComplete?: string | "on"
}

interface MUITextFieldState {
  isvalid: unknown;
  required?: boolean;
  errortext?: unknown;
  value: string;
  showPassword : boolean;
  type: any
}

class MUITextField extends React.Component<
  MUITextFieldProps,
  MUITextFieldState
> {
  constructor(props) {
    super(props);
    this.state = {
      isvalid: this.props.hasError || true,
      required: this.props.isRequired || false,
      errortext: this.props.error || null,
      value: this.props.error || "",
      showPassword : false,
      type: this.props.type,
    };
  }

  changeType = () => {
    if(this.state.type==='password')
    {
      this.setState({type: "text", showPassword: true})
    }
    else
    {
      this.setState({type: "password", showPassword: false})
    }
  }

  render() {
    const {
      hasError,
      id,
      label,
      onChange,
      onBlur,
      isDisabled,
      value,
      className,
      propsError,
      isAutoFocus,
      andormentText,
      helperText,
      ctaClick,
      ctaText,
      maxLength,
      autoComplete
    } = this.props;
    const { errortext } = this.state;
    return (
      <Fragment>
        <div
          className={`${styles.textfield} ${className ? className : ""}`.trim()}
          data-valid={!(propsError || errortext)}
          data-action={!!(helperText || ctaText)}
        >
          <TextField
            id={id}
            autoFocus={isAutoFocus}
            label={label}
            onChange={(e) => {onChange(validateInput(e))}}
            type={this.state.type}
            value={value}
            helperText={propsError}
            disabled={isDisabled}
            error={hasError ? true : false}
            fullWidth
            onBlur={onBlur}
            InputProps={{
              endAdornment: <InputAdornment position="end">{andormentText}</InputAdornment>,
            }}
            inputProps={{maxLength: maxLength || 50 }}
            onKeyPress={this.props.onKeyPress}
            variant="standard"
            color="info"
            autoComplete={autoComplete || "on"}
          />
          {this.props.showPasswordRules && <PasswordRules styles={styles} />}
          {this.props.showEye && <div onClick={this.changeType} className={this.state.showPassword ? styles.eyeBlink : styles.eyeOpen }></div> }
          {!propsError && (helperText || ctaText) && <div className={styles.actionRow}>
              <div><span>{helperText}</span></div>
              { (ctaClick || ctaText) && <div>
                { ctaClick ? <a onClick={ctaClick}>{ctaText}</a> : <span>{ctaText}</span> }
              </div>}
          </div>}
        </div>
      </Fragment>
    );
  }
}

export default MUITextField;
