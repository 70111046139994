import React from 'react';
import styles from '../../../../styles/modules/ui-components/checkbox.module.scss';



interface CheckboxProps {
    id?:any,
    onChange:(e) => any,
    checked?:boolean,
    name?:any
}

const Checkbox = (props:CheckboxProps) => {
    return(
        <label className={styles.el} htmlFor={props.id}>
            <input className={styles.el_in} type="checkbox" name={props.name ? props.name : ''} checked={props.checked} onChange={props.onChange} id={props.id}/>
            <em></em>
        </label>
    );
}


export default Checkbox;