import React from "react";
import styles from "../../styles/pages/myaccount.module.scss";
import { SendOTP } from "../../api/myaccount";
import MUITextField from "../common/ui-components/input/MUITextField";
import { otpRequestSuccess } from "../../tracking/segment/loginPage";
import { getPageURL } from "../../utils/tracking";
import moment from 'moment'
import { secondsToHHMM } from "../../utils";

const LogInStep = (props: any) => {
  const [error, setError] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>(props.phone);
  const [blockTime, setBlockTime] = React.useState<any>(''); 

  const sendOtp = (phone) => {
    SendOTP({
      application: "website",
      data: {
        phone: phone,
        type: "customer-login",
        template: "otp_customer_auth",
      },
    })
      .then((res: any) => {
        props.updateCustomerDetails({
          phone: phone,
          exists: res.data.exists,
        });
        otpRequestSuccess({ phone: phone, ...getPageURL(), source: "Log in" });
        setError("");
        props.progressStep(2);
        if (document.querySelector(`input#otp`)) {
          const a: any = document.querySelector(`input#otp`);
          setTimeout(() => {
            a.focus();
          }, 300);
        }
      })
      .catch((err: any) => {
        err.json().then((res:any) => {
          if(res.login_attempt_info){
            let blockTime = moment().add(res.remaining_seconds, 'seconds');
            let diff = blockTime.diff(moment(),'seconds')
            setBlockTime(blockTime)
            setError(`Your account has been blocked for next ${secondsToHHMM(diff)} due to multiple incorrect OTP attempts.`)
            let blockTimerInterval = setInterval(function () {
              diff = blockTime.diff(moment(),'seconds')
              setError(`Your account has been blocked for next ${secondsToHHMM(diff)} due to multiple incorrect OTP attempts.`)
              if(diff <= 0){
                clearInterval(blockTimerInterval)
                setBlockTime(null)
                setError('');
              }
              
            }, 1000);
          }
          else{
            console.log(err);
            setError("Please enter a valid Phone Number");
          }
        })
        
      });
  }
  const validate = () => {
    if (!phone) {
      setError("Required field");
    } else {
      if (phone.length != 10) {
        setError("Please enter a valid Phone Number");
      } else {
       sendOtp(phone)
      }
    }
  };

  let onPhoneChange = (e: any) => {
    let phone = e.target.value.trim().replace(/\D/g, "");
    if (phone.length < 11) {
      setPhone(phone);
    }
  };

  React.useEffect(() => {
    if (props.customer.phone_prefilled && props.customer.phone) {
      setPhone(props.customer.phone)
      sendOtp(props.customer.phone)
    }
  }, [])

  return (
    <div className={styles.input_parent}>
      <div className={styles.text_box}>
        <MUITextField
          className={styles.password}
          type="text"
          label="Mobile Number"
          onChange={(e) => {
            onPhoneChange(e);
          }}
          propsError={error}
          isRequired={true}
          value={phone}
          isAutoFocus={true}
          onKeyPress={(e) => {
            e.key === "Enter" && validate();
          }}
        />
      </div>

      <div className={`${styles.button} ${blockTime && blockTime > moment() && styles.disabled}`}>
        <a
          onClick={(e) => {
            (blockTime && blockTime > moment() || !blockTime) && validate();
          }}
        >
          Next
        </a>
      </div>
    </div>
  );
};

export default LogInStep;
