import React, { Fragment } from "react";
import PlainBanner from "../../components/common/PlainBanner";
import Head from 'next/head'
import { withRouter, NextRouter } from "next/router";
import { connect } from "react-redux";
import Section from "../../components/common/Section";
import styles from "../../styles/pages/myaccount.module.scss";
import Image from "../../components/common/Image";
import { Hidden } from "@mui/material";
import {
  setCurrentStepCustomer,
  setCustomerDetails,
  setIsAuthenticate,
} from "../../redux/actions/customerActions";
import {updateMobileNumber, updateTokenId} from "../../redux/actions/selfserveActions";
import LogInStep from "../../components/myaccount/login";
import PhoneStep from "../../components/myaccount/phoneStep";
import Config from "../../config";
import { detectBrowser, getParamsObjectFromString, getStaticPath } from "../../utils";
import EmailVerification from "../../components/myaccount/emailVerification";
import CustomerDetails from "../../components/myaccount/customerDetails";
import { trackPage } from "../../tracking";
import { getPageViewData } from "../../utils/tracking";
import { getIsAuthenticateCookie, loginWebForAppRender } from "../../api/cookies";
import { WebsiteSchema } from "@/constants/schema";
import SchemaLoader from "@/components/hoc/SchemaLoader";

interface MyAccountProps {
  customer: any;
  setCurrentStepCustomer: (data: any) => void;
  setCustomerDetails: (data: any) => void;
  setIsAuthenticate: (data: any) => void;
  updateMobileNumber: (data: any) => void;
  updateTokenId: (data: any) => void;
  router: NextRouter,
}
interface MyAccountPropsState {
  current_step_login: number;
  first_name: "";
  last_name:"",
  email: "";
  phone: "";
  address: "";
  landmark: "";
  city: "";
  pincode: "";
  state: "";
  email_activation_pending: boolean;
  customer_conflict: boolean;
  exists: boolean;
  BASE_URL: string;
  OG_IMAGE: string;
  leavePage: boolean;
  is_authenticate : boolean;
  customer_token: "";
  cart_token: "",
  params: object,
  isMobile:boolean
}

export async function getServerSideProps() {

  return {
    props: {
      pageName: 'login'
    },
  };
}

class MyAccount extends React.Component<MyAccountProps, MyAccountPropsState> {
  state: MyAccountPropsState = {
    current_step_login: 1,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    landmark: "",
    city: "",
    pincode: "",
    state: "",
    exists: false,
    BASE_URL: Config.BASE_URL,
    OG_IMAGE: getStaticPath(""),
    email_activation_pending: false,
    customer_conflict: false,
    leavePage: false,
    is_authenticate: false,
    customer_token: "",
    cart_token: "",
    params: {},
    isMobile:false
    };


  handleLeavePage = (event) => {
    const e = event || window.event;
    if(!this.state.leavePage && this.props.customer.current_step_login==2){
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return "";
    }else{
      return false;
    }
      
  };

  setLeavePage = (value) => {
    this.setState({leavePage:value})
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleLeavePage);
  }

  componentDidMount = () => {
      loginWebForAppRender(this.props.router)
      window.addEventListener("beforeunload", this.handleLeavePage);
      this.progressStep(1)
      trackPage('Login',{...getPageViewData()})
      this.setState({params:getParamsObjectFromString(window.location.href)['urlParams']})

      window.innerWidth < 769 ?
            this.setState({isMobile:true})
            :
            this.setState({isMobile:false})

  };


  componentDidUpdate = () => {
    const router = this.props.router;
    if((this.props.customer.current_step_login!=3 && this.props.customer.current_step_login!=2) && getIsAuthenticateCookie()){
      router.push('/')
    }
  }

  updateCustomerDetails = (data:any) => {
    this.setState({...data})
    this.props.setCustomerDetails({...data})
  }

  progressStep = (step) => {
    this.setState({current_step_login:step})
    this.props.setCurrentStepCustomer(step);
  };

  getActiveComponent = (step) => {
    switch (step) {
      case 1:
        return (
          <LogInStep
            progressStep={this.progressStep}
            updateCustomerDetails={this.updateCustomerDetails}
            {...this.props}
            {...this.state}
          />
        );
      case 2:
          return (
            <PhoneStep
              progressStep={this.progressStep}
              updateCustomerDetails={this.updateCustomerDetails}
              setLeavePage={this.setLeavePage}
              {...this.props}
              {...this.state}
            />
          );
      case 3:
        return (
          <CustomerDetails
            progressStep={this.progressStep}
            updateCustomerDetails={this.updateCustomerDetails}
            setLeavePage={this.setLeavePage}
            {...this.props}
            {...this.state}
          />
        )
      case 4:
        return (
            <EmailVerification
            progressStep={this.progressStep}
            updateCustomerDetails={this.updateCustomerDetails}
            setLeavePage={this.setLeavePage}
            {...this.props}
            {...this.state}
            />
        );
    }
  };


  render() {
    
    return (
      <Fragment>
        <Head>
        <title>{"Sign In"}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="description"
          content={""}
        ></meta>
        <meta
          property="og:title"
          content={"Sign In"}
        />
        <meta
          property="og:description"
          content={""}
        />
        {/* <meta property="og:url" content={`${this.state.BASE_URL}${this.props.router.asPath}`}/> */}
        <meta property="og:image" content={this.state.OG_IMAGE} />
        <SchemaLoader schema={WebsiteSchema} />
        </Head>
        
        {/* <link rel="canonical" href={`${this.state.BASE_URL}${this.props.router.asPath}`}></link> */}
        {!this.props.router.query.usertoken &&
        <PlainBanner title="" subtitle="">
        </PlainBanner>
        }
        {!this.props.router.query.usertoken &&
        <Section
          dataBg="shade-2"
          style={{
            paddingTop: "1px",
            paddingBottom: "64px",
            position: "relative",
          }}
        >
          <div className={`${styles.container} container`}>
            { this.state.params['kycstatus'] && <div className={`${styles.kyc_header}`}>
              <div className={styles.success_img}>
                <Image src={ this.state.params['kycstatus']==="true" ? "/static/images/kyc_success_header.png" : "/static/images/kyc_reject_header.png" } height="48px" width="48px"/> 
              </div>
              <div className={styles.success_msg}>
                {this.state.params['kycstatus'] === "true" ?
                  <>
                    <h5>
                      Great! Your information has been updated 
                    </h5>
                    <p>
                    Sign-in to your account to see plans and raise service request
                    </p>
                  </>
                  : 
                  <>
                    <h5>
                      Oops ! Sorry we missed the information
                    </h5> 
                    <p>
                      Sign-in to manage your details from “My Profile” page
                    </p>
                  </>
                }
              </div>
            </div>
            }
            <div className={`${styles.reset_password}`}>
              <div
                className={`${styles.left_content} col-12 col-sm-12 col-md-12 col-lg-6`}
              >
                <h2 className={styles.heading}>Sign in</h2>
                {this.props.customer.current_step_login==4?
                  <p className={styles.warning}>
                      <Image 
                      src="/static/images/warning_icon_36.png"
                      width="20"
                      height="20"
                      alt="warning" />
                      <span> Additional authentication required</span>
                  </p>
                  :
                  <p className={styles.subheading}>
                      Sign in using your registered{" "}
                      <span>Mobile Number</span>
                  </p>
                }
                <div className="signin_img">
                <Hidden mdDown>
                  { this.state.params['kycstatus'] ?
                    <Image 
                      src={ this.state.params['kycstatus']==="true" ? "/static/images/kyc_success_login.png" : "/static/images/kyc_reject_login.png" } 
                      height={this.state.params['kycstatus']==="true" ? "171px" : "240px"} 
                      width={this.state.params['kycstatus']==="true" ? "298px" : "249px" } />
                    :
                    <Image
                      src="/static/images/myaccount/signin_desktop.webp"
                      width="300"
                      height="250"
                      alt="sigin"
                    />
                  }
                  </Hidden>
                </div>
              </div>
              <div
                className={`${styles.right_content} col-12 col-sm-12 col-md-12 col-lg-6 myaccount`}
              >
                {this.getActiveComponent(
                  this.props.customer.current_step_login
                )}
              </div>
            </div>
            { this.state.params['kycstatus'] && 
              <>
              <div className={styles.success_img_web}> 
                <a href=  {this.state.isMobile?"https://onsitego.app.link/bwGZZe5Vwvb": detectBrowser()==="Safari"?"https://apps.apple.com/in/app/onsitego-repair-service/id1599462612": "https://play.google.com/store/apps/details?id=com.onsitego.consumer"} target="_blank" rel="noopener noreferrer">
                  <Image src={ this.state.params['kycstatus']==="true" ? "/static/images/kyc_success_banner.png" : "/static/images/kyc_reject_banner.png" } height="280px" width="1920px"/> 
                </a>  
              </div>
              <div className={styles.success_img_mv}>
                <a href= {this.state.isMobile?"https://onsitego.app.link/bwGZZe5Vwvb":detectBrowser()==="Safari"?"https://apps.apple.com/in/app/onsitego-repair-service/id1599462612": "https://play.google.com/store/apps/details?id=com.onsitego.consumer"} target="_blank" rel="noopener noreferrer">
                  <Image src={ this.state.params['kycstatus']==="true" ? "/static/images/kyc_success_mv.png" : "/static/images/kyc_reject_mv.png" } height="450px" width="762px"/> 
                </a>  
              </div>
              </>
            }
          </div>
        </Section>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    customer: state.customerReducer,
    cart: state.cart
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setCurrentStepCustomer: (data: any) => dispatch(setCurrentStepCustomer(data)),
    setCustomerDetails: (data: any) => dispatch(setCustomerDetails(data)),
    setIsAuthenticate: (data: any) => dispatch(setIsAuthenticate(data)),
    updateMobileNumber: (data:any) => dispatch(updateMobileNumber(data)),
    updateTokenId: (data:any) => dispatch(updateTokenId(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyAccount));
