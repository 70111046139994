const PasswordRules = props => {
    const {styles} = props
    return (
        <div className={styles.password_rules}>
            <i>?</i>
            <ul>
                <li>One small case letter</li>
                <li>One upper case letter</li>
                <li>One number</li>
                <li>One special character</li>
            </ul>
        </div>
    )
}

export default PasswordRules;
